import { SxStyleProp } from 'theme-ui';

export const Header: SxStyleProp = {
  bg: 'primary',
  width: '100%',
  height: '70px',
  pt: '1px',
  justifyContent: 'center',
  display: 'flex',
  position: 'fixed',
  zIndex: 100,
  top: 0,
};

export const LCLogo: SxStyleProp = {
  height: ['50px', '69px'],
  width: ['288px', '397px'],
  verticalAlign: 'middle',
};

export const HeaderContainer: SxStyleProp = {
  width: '100%',
  maxWidth: '1280px',
  display: 'flex',
  justifyContent: 'space-between',
};

export const HeaderLink: SxStyleProp = {
  m: ['0 8px', '0 4px', '0 8px'],
  p: ['8px 16px', '4px 16px', '8px 16px'],
  ':hover': { textDecoration: 'none', borderBottom: '4px solid #000' },
};

export const LinkText: SxStyleProp = {
  fontSize: [10, 8, 12],
  my: '8px',
  lineHeight: 'inherit',
  textDecoration: 'none',
  whiteSpace: 'nowrap',
};

export const FranchiseeSelectorButton: SxStyleProp = {
  display: 'flex',
  alignItems: 'center',
  borderRadius: '0px',
  background: 'transparent',
  color: 'black',
  cursor: 'pointer',
  gap: '8px',
  textTransform: 'uppercase', 
  m: '0 8px',
  p: '8px 16px',
  fontFamily: 'primary',
  fontWeight: 'bold',
  position: 'relative',
  ':hover': {
    borderBottom: '4px solid #000',
    borderTop: '4px solid transparent',
  },
};

export const Pin: SxStyleProp = {
  width: '12px',
  height: 'auto',
  m: 0,
};
