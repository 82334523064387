import * as React from 'react';
import SliceProvider from '@lce/slice_v2/SliceProvider';
import IePolyfill from '@lce/intl-util/src/IePolyfill';
import { Direction as IDirection } from '@lce/intl-types/src/IDirection';
import { IFooter, ILinkNavigation, ILocationButton } from '@lce/intl-types/src/ILayout';
import { IDefaultPageProps } from '@lce/intl-types/src/IDefaultPageProps';
import { fontDefinitions, theme } from '@lce/gatsby-theme-multi-site/src/ui/theme';

import Direction from '@lce/intl-ui/src/Patterns/Direction';
import Seo from '@lce/intl-ui/src/Patterns/Seo';
import Footer from '@lce/intl-ui/src/Patterns/Footer';

import Header from '../../Patterns/Header';
import StartYourOrder from '../../Patterns/StartYourOrder';
import { IStartYourOrder } from '../../Patterns/StartYourOrder/StartYourOrder';

export interface INavigation {
  franchiseeSelectorButton?: ILocationButton;
  navigationLinks: ILinkNavigation[];
}

export interface ILayoutProps extends IDefaultPageProps {
  footer: IFooter;
  navigation: INavigation;
  footerNavigation: INavigation;
  socialMediaLinks: string[];
  direction: IDirection;
  title?: string;
  description?: string;
  startYourOrder: IStartYourOrder;
  showStartYourOrder?: boolean;
}

const Layout: React.FC<ILayoutProps> = ({
  children,
  pathContext: { locale },
  footer,
  navigation,
  footerNavigation,
  socialMediaLinks,
  direction,
  title,
  description,
  startYourOrder,
  showStartYourOrder = true,
}) => (
  <SliceProvider global={[fontDefinitions]} theme={theme}>
    <IePolyfill />
    <Direction direction={direction} sx={{ mt: '70px' }}>
      <Seo description={description} title={title} />
      <Header locale={locale} navigation={navigation} />
      {showStartYourOrder && <StartYourOrder {...startYourOrder} />}
      {children}
      <Footer {...footer} {...footerNavigation} locale={locale} socialMediaLinks={socialMediaLinks} />
    </Direction>
  </SliceProvider>
);

export default Layout;
